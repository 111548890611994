html, body, #app {
	margin: 0;
	width: 100%;
	height: 100%;
}

* {
	box-sizing: border-box;
}

img {
	object-fit: contain;
}

.App {
	--header-bg: transparent;
	--app-bg: transparent;
	--fg: transparent;
	background-color: var(--app-bg);
	color: var(--fg);
	transition: background-color 250ms, color 250ms;
	min-height: 100vh;
}

.App > div:nth-child(1) {
	background-color: var(--header-bg);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 40px 0 0 0;
	transition: background-color 250ms;
}

.AppListGrid {
	display: grid;
	grid-template-columns: auto auto auto;
	gap: 30px;
	margin: 30px;
}

.MuiCard-root {
	display: flex;
	flex-direction: column;
}

#Alphabet-logo {
	width: 350px;
}

.HeaderButtons {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

.HeaderButtons > button {
	margin: 5px 10px;
}

thead th {
	white-space: nowrap;
}

.InvestmentGiantsPage {
	padding: 30px;
}

.CompanyInfo {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.CompanyInfo > .MuiTypography-root {
	flex: 1 1 auto;
}

.CompanyInfo > img {
	flex: 0 0 30%;
}

.CompanyInfo-fields {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 20px;
}

.CompanyInfo-fields > .CompanyInfoField {
	margin-right: 20px;
}

.CompanyInfo-fields > .CompanyInfoField:last-child {
	margin-right: 0;
}

.InvestmentGiantsPage-ownershipStakesGrid {
	--gap: 20px;
	display: grid;
	grid-template-columns: calc(25% - var(--gap)) calc(25% - var(--gap)) calc(25% - var(--gap)) 25%;
	gap: var(--gap);
}

.OwnershipStakeInfo {
	border-radius: 6px;
	padding: 10px;
}

.OwnershipStakeInfo > .OwnershipStakeInfo-header {
	position: relative;
}

.OwnershipStakeInfo > .OwnershipStakeInfo-header > .OwnershipStakeInfo-logoAndName > img {
	height: 90px;
	max-width: calc(100% - 125px);
}

.OwnershipStakeInfo > .OwnershipStakeInfo-header > .OwnershipStakeInfo-logoAndName > img[data-inverted="1"] {
	filter: invert();
}

.OwnershipStakeInfo > .OwnershipStakeInfo-header > .OwnershipStakeInfo-logoAndName > img[data-monochrome-inverted="1"] {
	filter: grayscale(1) invert();
}

.OwnershipStakeInfo > .OwnershipStakeInfo-header > .OwnershipStakeInfo-pieChart {
	position: absolute;
	top: 0;
	right: 0;
	width: 80px;
	height: 80px;
	border-radius: 100vw;
	background-color: #444;
	box-shadow: 0 0 15px #444;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.OwnershipStakeInfo > .OwnershipStakeInfo-header > .OwnershipStakeInfo-pieChart > .MuiTypography-root {
	line-height: 1.3;
}

.LogosDisplay {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.LogosDisplay > div {
	margin: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.LogosDisplay > div > img {
	max-height: 50px;
}

.LogosDisplay[data-bigger="1"] > div > img {
	max-height: 90px;
}

.LogosDisplay > div > .MuiTypography-root {
	text-align: center;
}

.CompanyInfoField {
	background-color: #292929 !important;
	padding: 5px 20px 5px 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.CompanyInfoField > .CompanyInfoField-right {
	margin-left: 10px;
}

@media screen and (max-width: 1600px) {
	.AppListGrid {
		grid-template-columns: auto auto;
	}

	.InvestmentGiantsPage-ownershipStakesGrid {
		grid-template-columns: calc(33% - var(--gap)) calc(34% - var(--gap)) 33%;
	}
}

@media screen and (max-width: 1040px) {
	.AppListGrid {
		grid-template-columns: auto;
	}

	.InvestmentGiantsPage-ownershipStakesGrid {
		grid-template-columns: auto;
	}
}

@media screen and (max-width: 520px) {
	h1 {
		font-size: 56px !important;
	}

	h5 {
		font-size: 21px !important;
	}

	#Alphabet-logo {
		width: 250px;
	}

	.HeaderButtons {
		flex-direction: column;
	}

	.CompanyInfo {
		flex-direction: column-reverse;
	}

	.OwnershipStakeInfo > .OwnershipStakeInfo-header > .OwnershipStakeInfo-logoAndName > img {
		max-width: 50%;
	}

	.CompanyInfo-fields {
		flex-direction: column;
		align-items: center;
	}

	.CompanyInfo-fields > .CompanyInfoField {
		margin-right: 0;
		margin-top: 20px;
	}

	.CompanyInfo-fields > .CompanyInfoField:first-child {
		margin-top: 0;
	}

	.CompanyInfo-fields > .CompanyInfoField:last-child {
		margin-right: 0;
	}
}
